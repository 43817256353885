import './App.css';
import { useRef, useEffect, useState } from "react";
import * as StackBlur from 'stackblur-canvas';
import { FaceMesh } from '@mediapipe/face_mesh/face_mesh';
import { Camera } from '@mediapipe/camera_utils/camera_utils';
import { isChrome } from 'react-device-detect';

function App() {
  const video = useRef(null);
  const canvas = useRef(null);

  // background caption
  const [caption, setCaption] = useState("Made with ❤️ by VovLove.");

  // initial positions
  let eyeLeftPt = {x: 0.4, y: 0.6}
  let eyeRightPt = {x: 0.4, y: 0.6}
  let eyeMidPt = {x: 0.4, y: 0.6}
  let currentOpacity = 0.0;
  let targetOpacity = 1.0;

  // configuration parameters
  var verticalOffset = 0.3;    // shift eyes up on the screen by this amount
  var zoomFactor = 1.0;

  // HACKY conditional way of keeping Desktop eye contact
  if (isChrome) {
    verticalOffset = 0.6; 
    zoomFactor = 1.5;
  }

  const eyeMaskOutsideBlur = 50; // blur in px the edges around the eyes
  const backgroundBlur = 80;     // blur in px of the background

  // event configuration parameters
  const distanceEventCloseRange = 0.2;  // no unit
  const distanceEventFarRange = 0.1;    // no unit
  const deviceAngleInRange = 10;        //deg
  const headAngleInRange = 15;          //deg

  // event strings
  const eventStringUserLeavesScreen = "filterUserLeaves";
  const eventStringUserEntersScreen = "filterUserEnters";
  var squelchEventsForNumberOfFrame = 20;
  var lastEventEmitted = eventStringUserEntersScreen;

  // executed from calculateTriggeEvents
  const triggerEvent = (name) => {
    if (lastEventEmitted !== name) {
      lastEventEmitted = name;
      squelchEventsForNumberOfFrame = 5;
      console.log("the EVENT: " + name);
      // KHARI: use this to trigger event!
    }
  };

  // executed from calculateDistanceAndAnglesFromLandmarks
  const calculateTriggerEvents = (distance, deviceAngle, headAngle) => {
    if (deviceAngle > deviceAngleInRange) {
      targetOpacity = 0.0;
      setCaption("rotate phone towards you");
      triggerEvent(eventStringUserLeavesScreen);
    } else if (headAngle > headAngleInRange) {
      targetOpacity = 0.0;
      setCaption("rotate head");
      triggerEvent(eventStringUserLeavesScreen);
    } else if (distance > distanceEventCloseRange) {
      targetOpacity = 0.0;
      setCaption("go farther away");
      triggerEvent(eventStringUserLeavesScreen);
    } else if (distance < distanceEventFarRange) {
      targetOpacity = 0.0;
      setCaption("come closer");
      triggerEvent(eventStringUserLeavesScreen);
    } else {
      targetOpacity = 1.0;
      setCaption("headAngle = " + headAngle.toFixed(2));
      setCaption("");
      triggerEvent(eventStringUserEntersScreen);
    }
  };

  // executed once per draw
  const calculateDistanceAndAnglesFromLandmarks = (landmarks) => {
      const filterWidth = 0.2; // smoothing
      eyeLeftPt =  {x: eyeLeftPt.x  * (1-filterWidth) + landmarks[226].x * filterWidth,
                    y: eyeLeftPt.y  * (1-filterWidth) + landmarks[226].y * filterWidth}
      eyeRightPt = {x: eyeRightPt.x * (1-filterWidth) + landmarks[446].x * filterWidth,
                    y: eyeRightPt.y * (1-filterWidth) + landmarks[446].y * filterWidth}
  
      var distance = Math.hypot(eyeRightPt.x - eyeLeftPt.x, eyeRightPt.y - eyeLeftPt.y);

      const cameraOpenAngle = 60;
      let deviceAngle = (Math.abs(eyeMidPt.x - 0.5) + Math.abs(eyeMidPt.y - 0.5)) * cameraOpenAngle / 2

      let nosePt  = landmarks[1];
      let fheadPt = landmarks[9];
      let chinPt  = landmarks[175];
      let leftPt  = landmarks[234];
      let rightPt = landmarks[454];

      var headAngleTopNormalizedDist   = Math.hypot(nosePt.x - fheadPt.x, nosePt.y - fheadPt.y) / distance;
      var headAngleBotNormalizedDist   = Math.hypot(nosePt.x - chinPt.x,  nosePt.y - chinPt.y)  / distance;
      var headAngleLeftNormalizedDist  = Math.hypot(nosePt.x - leftPt.x,  nosePt.y - leftPt.y)  / distance;
      var headAngleRightNormalizedDist = Math.hypot(nosePt.x - rightPt.x, nosePt.y - rightPt.y) / distance;

      const headOnHorizontNormDist = 0.7;

      var headAngleVerticalOffset = Math.abs(headAngleTopNormalizedDist - 0.6) + 
                                    Math.abs(headAngleBotNormalizedDist - 1.0);
      var headAngleHorizontOffset = Math.abs(headAngleLeftNormalizedDist - headOnHorizontNormDist) + 
                                    Math.abs(headAngleRightNormalizedDist - headOnHorizontNormDist);

      // TODO: BUG in TopNorm, doesnt work on iPhone, for now only tracking in horizontal
      let headAngle = ((headAngleVerticalOffset * 0) + headAngleHorizontOffset) * 20;  

      // Trigger events
      if (squelchEventsForNumberOfFrame <= 0) {
        calculateTriggerEvents(distance, deviceAngle, headAngle);
      } else {
        squelchEventsForNumberOfFrame--;
      }
  }

  // executed every time Mediapipe returns results
  const onResults = async (results) => {
    const landmarks = results.multiFaceLandmarks[0];
    const canvasCtx = canvas.current.getContext('2d');

    const videoWidth = video.current.videoWidth;
    const videoHeight = video.current.videoHeight;
    canvas.current.width = window.innerWidth;
    canvas.current.height = window.innerHeight;

    canvasCtx.translate(window.innerWidth/2 - videoWidth/2,
                window.innerHeight/2 - videoHeight/2);

    // calculate angle between eyes.
    if (landmarks) {
      // Emit events and hide/show filter
      calculateDistanceAndAnglesFromLandmarks(landmarks);

      // 0. CENTER ON THE EYES AND ZOOM/MOVE UP
      // ref https://github.com/google/mediapipe/blob/master/mediapipe/modules/face_geometry/data/canonical_face_model_uv_visualization.png
      const filterWidth = 0.2; // smoothing
      eyeLeftPt =  {x: eyeLeftPt.x  * (1-filterWidth) + landmarks[226].x * filterWidth,
                    y: eyeLeftPt.y  * (1-filterWidth) + landmarks[226].y * filterWidth}
      eyeRightPt = {x: eyeRightPt.x * (1-filterWidth) + landmarks[446].x * filterWidth,
                    y: eyeRightPt.y * (1-filterWidth) + landmarks[446].y * filterWidth}
      eyeMidPt =   {x: eyeMidPt.x   * (1-filterWidth) + landmarks[168].x * filterWidth,
                    y: eyeMidPt.y   * (1-filterWidth) + landmarks[168].y * filterWidth}

      // set opacity to hide filter when user is out of frame
      const opacityFade = 0.35;
      currentOpacity = currentOpacity * (1-opacityFade) + targetOpacity * opacityFade;
      canvasCtx.globalAlpha = currentOpacity;

      var angleDeg = Math.atan2(eyeRightPt.y - eyeLeftPt.y, eyeRightPt.x - eyeLeftPt.x);
      var distance = Math.hypot(eyeRightPt.x - eyeLeftPt.x, eyeRightPt.y - eyeLeftPt.y) * 1/zoomFactor;
      var gazeCorrectionOffset = videoHeight * verticalOffset;

      // this is for drawing the shape offscreen in order to use
      // shadow with offset for blurring the border of the eyes
      canvasCtx.translate(-window.innerWidth * 2, 0);

      canvasCtx.translate(0, -gazeCorrectionOffset);
      canvasCtx.translate(videoWidth/2, videoHeight/2);
      canvasCtx.scale(1/distance, 1/distance);
      canvasCtx.scale(0.45,0.45);
      canvasCtx.rotate(-angleDeg);
      canvasCtx.translate((-eyeMidPt.x * videoWidth) + videoWidth/2, (-eyeMidPt.y * videoHeight) + videoHeight/2);
      canvasCtx.translate(-videoWidth/2, -videoHeight/2);
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, videoWidth, videoHeight);

      // 1. DRAW MASK AROUND EYES
      let eyeMaskPoints = [234, 127, 162, 21,  // left side of face
                           // 104, 8, 333,     // above eyes
                           251, 389, 356, 454, // right side of face
                           197]                // beneath eyes

      canvasCtx.shadowColor = "rgba(127, 127, 127, 1.0)";
      canvasCtx.shadowBlur = eyeMaskOutsideBlur;

      // offset the shape and shadow, draws shadow only on canvas
      canvasCtx.shadowOffsetX = window.innerWidth*2;
      canvasCtx.shadowOffsetY = 0;

      canvasCtx.beginPath();
      canvasCtx.moveTo(landmarks[eyeMaskPoints[0]].x * videoWidth, landmarks[eyeMaskPoints[0]].y * videoHeight);
      eyeMaskPoints.forEach((pt, i) => {
        if (i>0) {
          canvasCtx.lineTo(landmarks[pt].x * videoWidth, landmarks[pt].y * videoHeight);
        }
      });
      canvasCtx.closePath();
      canvasCtx.fill();
      canvasCtx.save();

      canvasCtx.shadowOffsetX = 0;
      canvasCtx.shadowOffsetY = 0;
      canvasCtx.shadowColor = "transparent";

      canvasCtx.resetTransform();
      canvasCtx.translate(window.innerWidth/2 - videoWidth/2,
                  window.innerHeight/2 - videoHeight/2);
      canvasCtx.translate(0, -gazeCorrectionOffset);
      canvasCtx.translate(videoWidth/2, videoHeight/2);
      canvasCtx.scale(1/distance, 1/distance);
      canvasCtx.scale(0.45,0.45);
      canvasCtx.rotate(-angleDeg);
      canvasCtx.translate((-eyeMidPt.x * videoWidth) + videoWidth/2,
                          (-eyeMidPt.y * videoHeight) + videoHeight/2);
      canvasCtx.translate(-videoWidth/2, -videoHeight/2);

      // conditionally choose blurring mechanism based on browser compatability
      if (isChrome) {
        if (targetOpacity > 0.0) {
          // draw unblurred eyes
          canvasCtx.globalCompositeOperation = 'source-in';
          canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);
        }
        canvasCtx.globalCompositeOperation = 'destination-over';
        canvasCtx.filter = 'blur(' + backgroundBlur/2 + 'px)';
        canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);
      } else {
        // draw blurry background
        canvasCtx.globalCompositeOperation = 'xor';
        canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);
        StackBlur.canvasRGB(canvas.current, 0, 0, window.innerWidth, window.innerHeight, backgroundBlur);
        if (targetOpacity > 0.0) {
          // draw unblurred eyes
          canvasCtx.globalCompositeOperation = 'destination-atop';
          canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);
        }
      }
    }
  };

  const runFaceDetect = async () => {
      const faceMesh = new FaceMesh({locateFile: (file) => {
        return `./mediapipe/face_mesh/${file}`;
      }});

      faceMesh.setOptions({
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });

      faceMesh.onResults(onResults);

      const camera = new Camera(video.current, {
        onFrame: async () => {
          await faceMesh.send({image: video.current});
        },
        facingMode: 'user',
        width: 640,
        height: 480
      });

      camera.start();
  };


  useEffect(() => {
    runFaceDetect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas?.current?.readyState])

  return (
    <div className="App">
      <header className="App-header">
        <p style={{
        zIndex: 400,
      }}>{caption}</p>
      </header>
      <video
      ref={video}
      playsInline
      crossOrigin="true"
      style={{
        display:"none",
        position: "absolute",
        margin: "auto",
        textAlign: "center",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
      }}>
      </video>

      <canvas
      ref={canvas}
      style={{
        position: "absolute",
        margin: "auto",
        textAlign: "center",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
      }}
      />

    </div>
  );
}

export default App;
